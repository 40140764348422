






import { Component, Prop, Vue } from "vue-property-decorator";
import Statuses from "@/constants/Statuses";

@Component
export default class StatusIcon extends Vue {
  public name = "StatusIcon";

  @Prop({ required: true })
  public status!: string;

  public get vBindIcon() {
    const found = Statuses.find((s: any) => s.key === this.status);
    if (!found) return {};
    return {
      icon: [found.iconPrefix, found.icon],
      class: "text-white",
    };
  }

  public get vBindColor() {
    const found = Statuses.find((s: any) => s.key === this.status);
    if (!found) return {};
    return {
      style: { backgroundColor: found.color, height: "25px", width: "25px" },
    };
  }
}
