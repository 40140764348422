



















import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

@Component
export default class FscHorizontalTable extends Vue {
  public name = "FscHorizontalTable";

  @Prop({ type: Array, default: () => [] })
  public columns!: Array<any>;

  @Prop({
    type: Object,
  })
  public item!: Record<any, any>;

  public getValue(resource: any, key: string): string {
    return _.get(resource, key);
  }
}
