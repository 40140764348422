































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import DocumentButton from "@/components/Button/DocumentButton.vue";
import { formatStudentName } from "@/utils/NameUtil";

@Component({
  components: {
    FscSimpleCard,
    FscHorizontalTable,
    DocumentButton,
  },
})
export default class EducationStudentDocumentPreview extends Vue {
  public name = "EducationStudentDocumentPreview";

  @Prop()
  public studentDocument!: any;

  @Prop()
  public documentPreviewButtonDisable!: any;

  @Prop({ type: String })
  public createdByName!: string;

  protected columns = [
    {
      label: this.$tc("general.customer", 1),
      key: "student",
    },
    {
      label: this.$tc("general.license_class", 1),
      key: "licenseClass",
    },
  ];

  public removeLetterHead = false;

  public iconStyle = "fat";

  public get studentDocumentLicenceClassGet() {
    if (this.studentDocument.licenseClass) {
      return this.studentDocument.licenseClass;
    }
    return "";
  }

  public get studentDocumentStudentFullName() {
    if (this.studentDocument.student) {
      return formatStudentName(this.studentDocument.student.firstName, this.studentDocument.student.lastName);
    }
    return "";
  }

  public onStudentDocumentDocumentPreview(): void {
    if (this.studentDocument.id) {
      this.$emit("student-doc-preview", this.studentDocument.id, this.removeLetterHead);
    }
  }

  public get studentDocumentDescription() {
    if (this.studentDocument.description) {
      return this.studentDocument.description;
    }
    return "";
  }

  public get studentDocumentTime() {
    if (this.studentDocument.time) {
      const time = this.studentDocument.time.split(".");
      return time[0];
    }
    return "";
  }

  public onDocumentVisibleChange(document: any) {
    this.$emit("visible-change", document);
  }

  @Watch("studentDocument", { immediate: true, deep: true })
  public onStudentDocumentChange(document: any) {
    if (document) {
      this.iconStyle = document.driveBuzzVisible ? "fat" : "fas";
    }
  }
}
