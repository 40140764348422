import { Component, Vue } from "vue-property-decorator";
import CrudService from "@/services/CrudService";
import { AxiosError, AxiosResponse } from "axios";
import { IEducationEvent } from "@/interfaces/IEducationEvent";

const crudService = new CrudService();
const URL = "/education-events";
const URL_BOOKED = "/student-booked-product";

@Component
export default class EducationEventRequestMixin extends Vue {
  protected educationEvents: Array<IEducationEvent> = [];
  protected educationEventsSuccess = false;
  protected educationEventsError: Record<any, any> | null = null;
  protected educationEventsLoading = false;

  public async fetchEducationEvents(studentId: number): Promise<void> {
    this.educationEventsLoading = true;
    this.educationEventsSuccess = false;
    this.educationEventsError = null;
    return await crudService
      .findAll({
        resource: `${URL}/student/${studentId}`,
      })
      .then((response: AxiosResponse) => {
        this.educationEvents = response.data;
      })
      .catch((error: AxiosError) => {
        this.educationEventsError = error.response?.data;
      })
      .finally(() => {
        this.educationEventsLoading = false;
      });
  }

  protected productBooked: any = null;
  protected productBookedError: Record<any, any> | null = null;
  protected productBookedLoading = false;

  public async fetchProductBooked(bookingId: number): Promise<void> {
    this.productBookedLoading = true;
    this.productBookedError = null;
    return await crudService
      .findOne({
        resource: `${URL_BOOKED}`,
        id: bookingId,
      })
      .then((response: AxiosResponse) => {
        this.productBooked = response.data;
      })
      .catch((error: AxiosError) => {
        this.productBookedError = error.response?.data;
        this.productBooked = null;
      })
      .finally(() => {
        this.productBookedLoading = false;
      });
  }

  protected tuvBooked: any = null;
  protected tuvBookedError: Record<any, any> | null = null;
  protected tuvBookedLoading = false;

  public async fetchTuvBooked(tuvId: number): Promise<void> {
    this.tuvBookedLoading = true;
    this.tuvBookedError = null;
    return await crudService
      .findOne({
        resource: `${URL_BOOKED}`,
        id: tuvId,
      })
      .then((response: AxiosResponse) => {
        this.tuvBooked = response.data;
      })
      .catch((error: AxiosError) => {
        this.tuvBookedError = error.response?.data;
        this.tuvBooked = null;
      })
      .finally(() => {
        this.tuvBookedLoading = false;
      });
  }
}
