




import { Component, Prop, Vue } from "vue-property-decorator";
// @see https://dm4t2.github.io/vue-currency-input
import { CurrencyDisplay, CurrencyInputOptions, CurrencyInputValue } from "@/utils/vue-currency-input/api";
import { CurrencyInput } from "@/utils/vue-currency-input/currencyInput";

const findInput = (el: HTMLInputElement) => (el.tagName.toLowerCase() === "input" ? el : el.querySelector("input"));

@Component
export default class FscInputDecimal extends Vue {
  public name = "FscInputDecimal";

  protected numberInput: CurrencyInput | null = null;
  protected input: HTMLInputElement | null = null;

  @Prop()
  public value!: any;

  protected inputEvent = "input";
  protected changeEvent = "change";

  protected hasInputEventListener = !!this.$listeners[this.inputEvent];
  protected hasChangeEventListener = !!this.$listeners[this.changeEvent];

  private options: CurrencyInputOptions = {
    locale: "de-DE",
    currency: "EUR",
    currencyDisplay: CurrencyDisplay.hidden,
    valueRange: undefined,
    precision: 2,
    hideCurrencySymbolOnFocus: true,
    hideGroupingSeparatorOnFocus: true,
    hideNegligibleDecimalDigitsOnFocus: true,
    autoDecimalDigits: false,
    exportValueAsInteger: false,
    autoSign: true,
    useGrouping: false,
  };

  protected onInput(e: CustomEvent<CurrencyInputValue>): any {
    if (e.detail) {
      if (this.value !== e.detail.number) {
        this.$emit(this.inputEvent, e.detail.number);
      }
    }
  }

  protected onChange(e: CustomEvent<CurrencyInputValue>): any {
    if (e.detail) {
      this.$emit(this.changeEvent, e.detail.number);
    }
  }

  public mounted(): void {
    if (this.$refs.inputRef) {
      this.input = this.$refs.inputRef ? findInput(this.$refs.inputRef as HTMLInputElement) : null;

      if (this.input == null) {
        throw new Error("No input element found");
      } else {
        this.numberInput = new CurrencyInput(this.input, this.options);
        if (this.hasInputEventListener) {
          //@ts-ignore
          this.input.addEventListener("input", this.onInput);
        }
        if (this.hasChangeEventListener) {
          //@ts-ignore
          this.input.addEventListener("change", this.onChange);
        }
        // this.numberInput.setValue(this.value);
      }
    }

    this.$watch("value", () => this.numberInput?.setValue(this.value), {
      immediate: true,
    });
  }

  public unmounted(): void {
    if (this.hasInputEventListener) {
      //@ts-ignore
      this.input?.removeEventListener("input", this.onInput);
    }
    if (this.hasChangeEventListener) {
      //@ts-ignore
      this.input?.removeEventListener("change", this.onChange);
    }
  }
}
