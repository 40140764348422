











































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import PriceMixin from "@/mixins/PriceMixin";
import { namespace } from "vuex-class";
import DocumentButton from "@/components/Button/DocumentButton.vue";
import { formatInstructorName, formatStudentName } from "@/utils/NameUtil";

const PaymentRequestModule = namespace("payment-request");

@Component({
  components: {
    FscSimpleCard,
    FscHorizontalTable,
    DocumentButton,
  },
})
export default class EducationClassicPayPreview extends PriceMixin {
  public name = "EducationClassicPayPreview";

  @PaymentRequestModule.Action("findOne")
  public findPaymentRequestById: any;

  @PaymentRequestModule.Getter("getDataItem")
  public paymentRequest: any;

  @Prop()
  public classicPay!: any;

  @Prop()
  public documentPreviewButtonDisable!: any;

  @Prop({ type: String })
  public createdByName!: string;

  public classicPayLicenseClass!: any;

  protected columns = [
    {
      label: this.$t("students.amount"),
      key: "amountInEur",
    },
    {
      label: this.$tc("general.customer", 1),
      key: "student",
    },
    {
      label: this.$tc("general.license_class", 1),
      key: "licenseClass",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
    {
      label: this.$tc("general.driving_instructor", 1),
      key: "instructor",
    },
  ];

  public removeLetterHead = false;

  public mounted(): void {
    this.classicPayLicenseClass = this.classicPay.licenseClass;
    this.findPaymentRequestById({
      id: this.classicPay.id,
      resource: "payment-requests",
    });
  }

  protected get studentFullName(): string {
    return this.classicPay.student ? formatStudentName(this.classicPay.student.firstName, this.classicPay.student.lastName) : "";
  }

  protected get instructorFullName(): string {
    return this.classicPay.instructor ? formatInstructorName(this.classicPay.instructor.firstName, this.classicPay.instructor.lastName) : "";
  }

  public onClassicPayDocumentPreview(): void {
    this.$emit("classic-doc-preview", this.paymentRequest.studentDocumentId, this.removeLetterHead);
  }

  public get paymentRequestTime() {
    if (this.paymentRequest.time) {
      const time = this.paymentRequest.time.split(".");
      return time[0];
    }
    return "";
  }

  public get classicPayNumber() {
    if (this.classicPay.description) {
      const description = this.classicPay.description.split("|");
      return description[1];
    }
    return " ";
  }
  @Watch("classicPay", { immediate: true, deep: true })
  public onClassicPayChange(classicPay: any): void {
    if (classicPay) {
      this.classicPayLicenseClass = classicPay.licenseClass;
      this.findPaymentRequestById({
        id: classicPay.id,
        resource: "payment-requests",
      });
    }
  }
}
