








import { Vue, Component, Prop } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";
import moment from "moment/moment";
import { IFibuFixTransactionRecordDto } from "@/interfaces/IFibuFixTransactionRecordDto";
import { PropType } from "vue";

@Component({
  components: { FscModal },
})
export default class FibuFixTransactionsModal extends Vue {
  public name = "FibuFixTransactionsModal";

  @Prop({ required: true, type: String })
  public fibufixTransactionsModalId!: string;

  @Prop({ required: true, type: Array as PropType<Array<IFibuFixTransactionRecordDto>> })
  public fibufixItems!: Array<IFibuFixTransactionRecordDto>;

  @Prop({ required: true, type: Boolean, default: () => false })
  public fibuFixTransactionsLoading!: boolean;

  private fibufixFields = [
    { key: "transactionId", label: "Transaction ID", sortable: true },
    { key: "amount", label: "Amount", sortable: true },
    {
      key: "successfull",
      label: "Successful",
      sortable: true,
      formatter: function (value: any, key: any, item: any) {
        return value ? "Yes" : "No";
      },
    },
    {
      key: "cancelled",
      label: "Cancelled",
      sortable: true,
      formatter: function (value: any, key: any, item: any) {
        return value ? "Yes" : "No";
      },
    },
    { key: "invoiceId", label: "Invoice ID", sortable: true },
    { key: "accountId", label: "Account ID", sortable: true },
    { key: "from", label: "From", sortable: true },
    { key: "receiptId", label: "Receipt ID", sortable: true },
    { key: "method", label: "Method", sortable: true },
    { key: "type", label: "Type", sortable: true },
    { key: "paymentId", label: "Payment ID", sortable: true },
    {
      key: "createdOn",
      label: "Created On",
      sortable: true,
      formatter: function (value: any, key: any, item: any) {
        return value ? moment(value).format("DD.MM.YYYY HH:mm") : "";
      },
    },
  ];
}
