






















































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import Validation from "@/components/Validation.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

@Component({
  components: {
    FscSimpleCard,
    FscInputDecimal,
    SaveButton,
    AbortButton,
    Validation,
  },
})
export default class ExternalServiceForm extends Vue {
  public name = "ExternalServiceForm";

  @Prop()
  public externalServiceData!: any;

  @Prop()
  public studentEducations!: any;

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  public selectedEducation = "";

  public mounted() {
    if (this.studentEducations && this.studentEducations.length === 1) {
      this.selectedEducation = this.studentEducationOptions[0];
    }
  }

  protected onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    this.$emit("on-submit", this.selectedEducation);
  }

  protected onClose(): void {
    this.$emit("on-close");
  }

  public get studentEducationOptions() {
    if (this.studentEducations) {
      const educations = this.studentEducations.map((edc: any) => {
        return { id: edc.id, mainLicenseClass: edc.mainLicenseClass };
      });
      return educations;
    }
    return [];
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      selectedEducation: { required },
    };
  }
}
