export const THEORY_LESSON = "THEORY_LESSON";
export const DRIVING_LESSON = "DRIVING_LESSON";
export const THEORY_EXAM = "THEORY_EXAM";
export const PRACTICAL_EXAM = "PRACTICAL_EXAM";
export const PRODUCT_BOOKING = "PRODUCT_BOOKING";
export const PAYMENT = "PAYMENT";
export const NOTE = "NOTE";
export const STUDENT_DOCUMENT = "STUDENT_DOCUMENT";
export const EMAIL = "EMAIL";
export const FINAL_INVOICE = "FINAL_INVOICE";
export const PARTIAL_INVOICE = "PARTIAL_INVOICE";
export const CANCELLATION_INVOICE = "CANCELLATION_INVOICE";
export const CUSTOM_BOOKING = "CUSTOM_BOOKING";
export const COST_BEARER_PAYMENT = "COST_BEARER_PAYMENT";
export const PAYMENT_REQUEST = "PAYMENT_REQUEST";
export const PROOF_OF_TRAINING = "PROOF_OF_TRAINING";
export const CLASSIC_PAY_PAYMENT = "CLASSIC_PAY_PAYMENT";
export const LIVE_PAY_PAYMENT = "LIVE_PAY_PAYMENT";
export const EXTERNAL_SERVICES_PACK = "EXTERNAL_SERVICES_PACK";
export const COST_BEARER_INVOICE = "COST_BEARER_INVOICE";
export const COST_BEARER_PAYMENT_REQUEST = "COST_BEARER_PAYMENT_REQUEST";
export const COST_BEARER_NOTE = "COST_BEARER_NOTE";
export const MESSAGE = "MESSAGE";
export const COST_BEARER_CUSTOM_BOOKING = "COST_BEARER_CUSTOM_BOOKING";

export const EDUCATION_EVENT_TYPES = [
  {
    id: 101,
    type: THEORY_LESSON,
    icon: "screen-users",
  },
  {
    id: 102,
    type: DRIVING_LESSON,
    icon: "car",
  },
  {
    id: 103,
    type: THEORY_EXAM,
    icon: "graduation-cap",
  },
  {
    id: 104,
    type: PRACTICAL_EXAM,
    icon: "graduation-cap",
  },
  {
    id: 105,
    type: PRODUCT_BOOKING,
    icon: "coin",
  },
  {
    id: 106,
    type: PAYMENT,
    icon: "livepay",
    custom: true,
  },
  {
    id: 107,
    type: NOTE,
    icon: "note",
  },
  {
    id: 108,
    type: STUDENT_DOCUMENT,
    icon: "file",
  },
  {
    id: 109,
    type: EMAIL,
    icon: "envelope",
  },
  {
    id: 110,
    type: FINAL_INVOICE,
    icon: "file-plus",
  },
  {
    id: 111,
    type: PARTIAL_INVOICE,
    icon: "file-plus",
  },
  {
    id: 112,
    type: CANCELLATION_INVOICE,
    icon: "file-invoice-dollar",
  },
  {
    id: 113,
    type: CUSTOM_BOOKING,
    icon: "coin",
  },
  {
    id: 114,
    type: COST_BEARER_PAYMENT,
    icon: "file-invoice-dollar",
  },
  {
    id: 115,
    type: PAYMENT_REQUEST,
    icon: "classic",
    custom: true,
  },
  {
    id: 116,
    type: PROOF_OF_TRAINING,
    icon: "signature",
  },
  {
    id: 117,
    type: CLASSIC_PAY_PAYMENT,
    icon: "coin",
  },
  {
    id: 118,
    type: LIVE_PAY_PAYMENT,
    icon: "livepay",
    custom: true,
  },
  {
    id: 119,
    type: EXTERNAL_SERVICES_PACK,
    icon: "arrow-down-to-square",
  },
  {
    id: 120,
    type: COST_BEARER_INVOICE,
    icon: "file-invoice-dollar",
  },
  {
    id: 121,
    type: COST_BEARER_PAYMENT_REQUEST,
    icon: "classic",
    custom: true,
  },
  {
    id: 122,
    type: COST_BEARER_NOTE,
    icon: "note",
  },
  {
    id: 123,
    type: MESSAGE,
    icon: "messages",
  },
  {
    id: 124,
    type: COST_BEARER_CUSTOM_BOOKING,
    icon: "coin",
  },
];
