

















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { IEducationEvent } from "@/interfaces/IEducationEvent";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import TextDecimal from "@/components/TextDecimal.vue";
import { IDrivingLesson } from "@/interfaces/IDrivingLesson";
import { toDateTime } from "@/utils/DateTime";
import EducationEventDetails from "@/views/Student/EducationEvent/Details/EducationEventDetails.vue";
import axios from "@/utils/axios";
import { AxiosResponse } from "axios";
import { mixins } from "vue-class-component";
import EducationEventRequestMixin from "@/mixins/Request/EducationEventRequestMixin";
import { formatInstructorName } from "@/utils/NameUtil";

@Component({
  methods: { formatInstructorName },
  components: {
    EducationEventDetails,
    TextDecimal,
    FscHorizontalTable,
    FscSimpleCard,
  },
})
export default class DrivingLessonDetails extends mixins(EducationEventRequestMixin) {
  public name = "DrivingLessonDetails";

  @Prop({ required: true })
  public educationEvent!: IEducationEvent;

  @Prop({ required: true })
  public item!: IDrivingLesson;

  @Prop({ required: false, type: Boolean })
  public loading!: boolean;

  @Prop({ type: String })
  public createdByName!: string;

  public sourceSignature = "";

  protected columns = [
    {
      label: this.$tc("general.customer", 1),
      key: "student.name",
    },
    {
      label: this.$tc("general.license_class", 1),
      key: "licenseClass",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
    {
      label: this.$tc("calendar.form_time", 1),
      key: "time",
    },
    {
      label: this.$tc("general.duration", 1),
      key: "duration",
    },
    {
      label: this.$tc("calendar.vehicle", 1),
      key: "vehicle.brandAndModel",
    },
    {
      label: this.$tc("general.driving_instructor", 1),
      key: "instructor",
    },
    {
      label: this.$t("general.signature"),
      key: "signature",
    },
  ];

  public formatDateTime(dateTime: string | null): string {
    return toDateTime(dateTime);
  }

  protected get product(): string {
    return this.item ? this.item.product.name : "";
  }

  protected get createdOn(): string {
    return this.item?.createdOn || "";
  }
  protected get bookedOn(): string {
    if (!this.productBooked) return "";
    return this.productBooked?.createdOn || "";
  }

  protected get priceInEur(): number | null {
    return this.item?.priceInEur || null;
  }

  /* axios is used, because with vuex there might be a conflict if multiple tabs with services (driving lesson) for the different users are opened */
  @Watch("item")
  public async onStudentDocumentChange(item: IDrivingLesson): Promise<void> {
    if (!item || !item.studentSignatureDocumentId) {
      this.sourceSignature = "";
      return;
    }

    return await axios({
      method: "get",
      url: `/student-documents/${item.studentSignatureDocumentId}`,
      responseType: "arraybuffer",
    }).then((response: AxiosResponse) => {
      const mimeType = response.headers["content-type"].toLowerCase();
      const blob = response.data;
      this.sourceSignature =
        "data:" +
        mimeType +
        ";base64," +
        //@ts-ignore
        new Buffer(blob, "binary").toString("base64");
    });
  }

  @Watch("item")
  public async onItemChange(): Promise<void> {
    // the response binds this.productBooked from the mixin
    this.productBooked = null;
    if (this.item && this.item.booked) {
      await this.fetchProductBooked(this.item.productBookingId);
    }
  }
}
